import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [0,13,2,8,10,3];

export const dictionary = {
		"/": [~14],
		"/admin": [55,[13]],
		"/admin/crawl": [~56,[13]],
		"/admin/crawl/[job]": [~57,[13]],
		"/admin/waitlist": [~58,[13]],
		"/(public)/auth/fail": [50,[12]],
		"/(public)/auth/verify": [51,[12]],
		"/(public)/contact": [52,[12]],
		"/integrations/storyblok/authorize": [~59],
		"/(public)/login": [~53,[12]],
		"/(public)/logout": [54,[12]],
		"/(protected)/me": [~38,[2,8]],
		"/(protected)/me/invite": [~39,[2,8]],
		"/(protected)/me/invite/[inviteId]": [~40,[2,8]],
		"/(protected)/me/new": [~41],
		"/(protected)/me/organisations": [~42,[2,8]],
		"/(protected)/me/organisations/[orga]": [~43,[2,8,10]],
		"/(protected)/me/organisations/[orga]/billing": [~44,[2,8,10]],
		"/(protected)/me/organisations/[orga]/billing/change": [~45,[2,8,10]],
		"/(protected)/me/organisations/[orga]/billing/success": [~46,[2,8,10]],
		"/(protected)/me/organisations/[orga]/collaborators": [~47,[2,8,10]],
		"/(protected)/me/settings": [48,[2,8,11]],
		"/(protected)/me/settings/notifications": [~49,[2,8,11]],
		"/(protected)/[agent]": [~15,[2,3],[,4]],
		"/(protected)/[agent]/audiences": [~16,[2,3],[,4]],
		"/(protected)/[agent]/changes": [~17,[2,3],[,4]],
		"/(protected)/[agent]/cohorts": [~18,[2,3],[,4]],
		"/(protected)/[agent]/cohorts/[id]": [~19,[2,3],[,4]],
		"/(protected)/[agent]/goals": [~20,[2,3],[,4]],
		"/(protected)/[agent]/insights": [~21,[2,3],[,4]],
		"/(protected)/[agent]/keywords": [~22,[2,3],[,4]],
		"/(protected)/[agent]/pages": [~23,[2,3],[,4]],
		"/(protected)/[agent]/pages/[id]": [~24,[2,3],[,4]],
		"/(protected)/[agent]/personas": [~25,[2,3],[,4]],
		"/(protected)/[agent]/settings": [26,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/danger": [~27,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/guides": [~28,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/notifications": [~29,[2,3,5],[,4]],
		"/(protected)/[agent]/settings/setup": [~30,[2,3,5],[,4]],
		"/(protected)/[agent]/strategies": [~31,[2,3],[,4]],
		"/(protected)/[agent]/tests": [~32,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]": [~33,[2,3],[,4]],
		"/(protected)/[agent]/tests/[id]/edit": [~34],
		"/(protected)/[agent]/tests/[id]/goals": [~35,[2,3],[,4]],
		"/(protected)/[agent]/welcome": [~36,[2,3],[,4]],
		"/(protected)/[agent]/welcome/[step]": [~37,[2,3,7],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';